import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/unit");
  }
  get(id) {
    return axios.get(`/unit/${id}`);
  }
  create(data) {
    return axios.post("/unit", data);
  }
  update(id, data) {
    return axios.put(`/unit/${id}`, data);
  }
  delete(id) {
    return axios.delete(`/unit/${id}`);
  }
  deleteAll() {
    return axios.delete(`/unit`);
  }
  findByTitle(title) {
    return axios.get(`/unit?title=${title}`);
  }
  changeStatus(id) {
    return axios.get(`changeUnitStatus/${id}`);
  }
}
export default new DataService();